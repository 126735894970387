'use client'
import { SearchType, searchTypes } from '@/app/(site)/search/types'
import { giphyLocalUrl, publicRuntimeConfig } from '@/app/util/env'
import createPlaceholderGif from '@/app/util/gif-skeleton'
import { getKevelGifIdByType } from '@/app/util/kevel'
import { processSearchTerm } from '@/app/util/search-term'
import { useGiphyFetch } from '@/app/util/use-gf'
import AdsContext from '@/context/ads'
import { Result, SearchOptions } from '@giphy/js-fetch-api'
import { IChannel, IGif } from '@giphy/js-types'
import { useParams, useSearchParams } from 'next/navigation'
import { Dispatch, SetStateAction, useContext, useEffect, useMemo } from 'react'
import useSWR from 'swr'
import useSWRImmutable from 'swr/immutable'
import { GenericResult } from 'ui/src/hooks/use-fetch-data'
import { insertItem, replaceItem } from 'utils'

const { apiUrl, mobileApiKey } = publicRuntimeConfig
const apiUrlV2 = apiUrl.replace('v1', 'v2')

type TrendingSearch = { name: string; analytics_response_payload: string }

const getParams = (obj: any) => new URLSearchParams(obj).toString()

export function useGif(id?: string) {
    const gf = useGiphyFetch()
    return useSWR<IGif>(id ? id : null, (id: string) => gf.gif(id).then((res) => res.data))
}

/**
 * If there is a kevel ad, insert a placeholder into a gifs array
 * @param gifs the gifs array to insert into
 * @param placeholderId the id of the placeholder, to be used later to remove it
 * @param targetIndex the location of the placeholder in the gifs array, default 0
 * @returns
 */
export function useGifsWithPlaceholder(gifs: IGif[], placeholderId: string, targetIndex: number = 0) {
    const { isKevelAdEnabled } = useContext(AdsContext)
    const hasKevelAd = isKevelAdEnabled('grid')
    return useMemo(() => {
        const placeHolder = createPlaceholderGif({ id: placeholderId }, { width: 250, height: 250 })
        if (hasKevelAd && !gifs.some((g) => g.id === placeholderId) && targetIndex < gifs.length) {
            return insertItem(gifs, targetIndex, placeHolder)
        } else {
            return gifs
        }
    }, [gifs, hasKevelAd, targetIndex, placeholderId])
}

/**
 *
 * @param setGifs
 * @param placeholderId
 * @returns
 */
export function useGifUpdater(setGifs: Dispatch<SetStateAction<IGif[]>>, placeholderId: string) {
    const { kevelClientData, isKevelAdEnabled } = useContext(AdsContext)
    const hasKevelAd = isKevelAdEnabled('grid')
    // AdsContext will fetch a gif for the banner and grid
    const { data: adGif } = useGif(kevelClientData ? getKevelGifIdByType(kevelClientData, 'grid') : '')
    useEffect(() => {
        if (hasKevelAd && adGif) {
            setGifs((gifs) => {
                const placeholderIndex = gifs.findIndex((g) => g.id === placeholderId)
                if (placeholderIndex !== -1) {
                    return replaceItem(gifs, placeholderIndex, adGif)
                }
                return gifs
            })
        }
    }, [adGif, hasKevelAd, placeholderId, setGifs])
}

export function useSearchType(): SearchType {
    const params = useParams()

    // search params are on the homepage: ?type=stickers
    const searchParams = useSearchParams()
    const searchParamType = searchParams.get('type')?.toLowerCase() as any
    if (searchTypes.includes(searchParamType)) {
        return searchParamType
    }
    // params are on search pages: pizza-stickers
    const [, type] = params.term ? processSearchTerm(params.term as string) : []
    if (searchTypes.includes(type as any)) {
        return type as SearchType
    }
    return 'gifs'
}

export function useImmutableChannel(channelId: number | string | null = null) {
    // we will load an immutable channel and apply mutations through the SWR mutate api
    return useSWRImmutable<IChannel>(
        channelId ? `${giphyLocalUrl}/api/v4/channels/${channelId}?fetch_children=true` : null
    )
}

export const useTrendingSearches = (term: string = '', options: SearchOptions = {}) =>
    useSWR<GenericResult<TrendingSearch>>(
        `${apiUrlV2}trending/searches?${getParams({
            ...options,
            q: term,
            api_key: mobileApiKey,
        })}`
    )

export const useSearchChannel = (channelName: string, options: SearchOptions = {}) =>
    useSWR<GenericResult<IChannel>>(
        channelName
            ? `${apiUrl}channels/search?${getParams({
                  ...options,
                  q: channelName.replace('@', ''),
                  api_key: mobileApiKey,
              })}`
            : null
    )

export const useSearchChannelByTerm = (term: string, options: SearchOptions = {}) =>
    useSWR<GenericResult<IChannel>>(
        `${apiUrl}channels/search?${getParams({
            ...options,
            q: term,
            api_key: mobileApiKey,
        })}`
    )

type SearchName = { analytics_response_payload: string; name: string; response_id: string }
type SearchTagsResult = { data: SearchName[] } & Result
export const useSearchTags = (term: string, options: SearchOptions = {}) =>
    useSWR<SearchTagsResult>(
        `${apiUrl}gifs/search/tags?${getParams({
            ...options,
            q: term,
            api_key: mobileApiKey,
        })}`
    )
